import React, { useContext, memo } from 'react';
import PropTypes from 'prop-types';
import { Text, Paragraph, Box, Stack, ThemeContext, Grid } from 'grommet';
import { m } from 'framer-motion';
import { keys, isObject } from '../../lib/nodash';
import he from 'he';
import loadable from '@loadable/component';

import formatCurrency from '../../lib/formatCurrency';
import { getProductPath } from '../../lib/product';
import ImgixImage from '../ImgixImage';
import TextUnderlined from '../TextUnderlined';
import SmartLink from '../SmartLink';
import useAdjustedHeadingSize from '../useAdjustedHeadingSize';

const ProductBadges = loadable(() => import('./ProductBadges'));

const ProductCardSimple = ({
  title,
  short_description,
  imageUrl,
  hideImage = false,
  price,
  tags,
  showProductBadges = true,
  minHeight = '270px',
  srcSetSizes,
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  const productPath = getProductPath({ handle: rest.handle });
  const isKeywordSize = keys(theme.global.size).indexOf(rest.imageWidth) > -1;
  const imageSize = rest.imageWidth
    ? isObject(rest.imageWidth)
      ? parseInt(theme.global.size[rest.imageWidth.max] || rest.imageWidth.max)
      : isKeywordSize
      ? parseInt(theme.global.size[rest.imageWidth])
      : parseInt(rest.imageWidth)
    : 180;
  const titleStyle = {
    ...useAdjustedHeadingSize(5),
    letterSpacing: '1px',
    textTransform: 'uppercase',
  };

  return (
    <Box {...rest} className="product-card-simple">
      <Stack anchor="top-right" fill className="badges-container">
        <Grid
          align="start"
          justify="center"
          columns={{ count: 1, size: 'auto' }}
          rows={[`minmax(${minHeight}, auto)`, '1fr']}
          fill
        >
          <Box
            flex={true}
            className="image-container"
            justify="center"
            fill="vertical"
          >
            <m.div
              animate={{ opacity: hideImage ? 0 : 1 }}
              initial={{ opacity: 1 }}
              transition={{ duration: 0.2 }}
            >
              <Box flex={true} alignSelf="center" fill>
                <SmartLink plain to={productPath} fill>
                  <ImgixImage
                    fit="contain"
                    fill
                    srcSetOptions={{
                      w: srcSetSizes ? undefined : imageSize,
                      h: srcSetSizes ? undefined : imageSize,
                      auto: ['format', 'compress'],
                    }}
                    src={imageUrl}
                    alt={title}
                    a11yTitle={`Photo of ${title}`}
                    sizes={srcSetSizes}
                  />
                </SmartLink>
              </Box>
            </m.div>
          </Box>
          <Box pad="small" flex={true} justify="start">
            <Text textAlign="center" style={titleStyle} weight={500}>
              <SmartLink plain to={productPath}>
                {he.decode(title)}
              </SmartLink>
            </Text>
            {short_description && (
              <Paragraph textAlign="center">{short_description}</Paragraph>
            )}
            <Box align="center">
              <TextUnderlined size="small" weight={500} textAlign="center">
                {price && formatCurrency(price)}
              </TextUnderlined>
            </Box>
          </Box>
        </Grid>
        {showProductBadges && <ProductBadges tags={tags} />}
      </Stack>
    </Box>
  );
};

ProductCardSimple.propTypes = {
  title: PropTypes.string.isRequired,
  short_description: PropTypes.string,
  imageSize: PropTypes.number,
  imageUrl: PropTypes.object,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tags: PropTypes.array,
  hideImage: PropTypes.bool,
  expandImageContainer: PropTypes.bool,
  showProductBadges: PropTypes.bool,
  minHeight: PropTypes.string,
  srcSetSizes: PropTypes.string,
};

export default memo(ProductCardSimple);
