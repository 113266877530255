import React, { useMemo, memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { get, head } from '../../lib/nodash';
import { Box, Stack } from 'grommet';
import { m, LazyMotion } from 'framer-motion';

import useHover from '../useHover';
import useMobile from '../useMobile';
import {
  getProductPath,
  canQuickAdd,
  parseTagFeatures,
  getMaxPrice,
  findMetafieldValue,
} from '../../lib/product';
import { findImage } from '../../lib/findImage';
import ProductCardSimple from './ProductCardSimple';
import ButtonsProductCartOptions from '../ButtonsProductCartOptions';
import ImgixImage from '../ImgixImage';
import SmartLink from '../SmartLink';
import ButtonAddToCart from '../ButtonAddToCart';
import { track } from '../../lib/analytics';
import { PRODUCT_LIST_PRODUCT_HOVERED } from '../../lib/analytics/segmentActions';

const loadFeatures = () =>
  import('../../lib/framer/motionFeatures.js').then((res) => res.default);

const ProductCardHoverDetails = ({ product, collection, srcSetSizes }) => {
  const {
    title,
    description,
    shopifyId,
    id,
    handle,
    variants,
    media,
    tags,
    priceRangeV2,
    ...rest
  } = product;
  const productVariants = variants || product.variants;
  const [hoverRef, isHovered] = useHover();
  const isMobile = useMobile();

  const variant = head(productVariants);

  const shortDescription = findMetafieldValue(
    'short_description',
    get('metafields', product)
  );

  const tagFeatures = parseTagFeatures(product.tags);
  const collectionImage = useMemo(
    () =>
      tagFeatures.comingSoon
        ? 'https://cdn.shopify.com/s/files/1/0051/6295/8946/t/2/assets/Backdrop_x_Comingsoon_Logo_Lockup_540x.png?v=16298168946144613559'
        : findImage(media, '_grid') || media[0]?.image?.originalSrc,
    [media, tagFeatures]
  );
  const lifestyleImage = useMemo(() => findImage(media, '_hover'), [media]);

  useEffect(() => {
    if (isHovered) {
      track(
        PRODUCT_LIST_PRODUCT_HOVERED,
        {
          product: title,
        },
        {
          integrations: {
            Klaviyo: false,
          },
        }
      );
    }
  }, [isHovered]);

  return (
    <LazyMotion strict features={loadFeatures}>
      <Box
        {...rest}
        ref={hoverRef}
        pad="none"
        background={{ color: 'lightGray' }}
        className="product-card-hover-details"
      >
        <Stack fill>
          <Box
            fill
            justify="center"
            className="wrapper"
            pad={isMobile ? undefined : { top: 0, bottom: '98px' }}
            gap="medium"
          >
            <ProductCardSimple
              title={title}
              description={description}
              short_description={shortDescription || description}
              imageUrl={collectionImage}
              flex={true}
              fill={true}
              justify="between"
              hideImage={isHovered && !isMobile}
              price={get('price', variant) || getMaxPrice(priceRangeV2)}
              imageWidth={{ max: '270px' }}
              pad="none"
              tags={tags}
              handle={handle}
              showProductBadges={false}
              srcSetSizes={srcSetSizes}
            />
            {isMobile && (
              <>
                {canQuickAdd(product) && (
                  <ButtonAddToCart
                    product={{
                      title,
                      description,
                      shopifyId,
                      variants: productVariants,
                      id,
                    }}
                    variant={variant}
                  />
                )}
              </>
            )}
          </Box>

          <m.div
            animate={{
              opacity: isHovered && !isMobile ? 1 : 0,
            }}
            initial={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            style={{ height: '100%', width: '100%' }}
          >
            <SmartLink
              fill
              to={getProductPath({ handle })}
              style={{ textDecoration: 'none' }}
              as={Box}
              state={{ collection }}
            >
              <Box
                fill
                border={{ side: 'all', size: 'small' }}
                justify="between"
              >
                {lifestyleImage && (
                  <Box fill="vertical" height={{ max: '240px' }}>
                    <ImgixImage
                      fit="cover"
                      src={lifestyleImage}
                      srcSetOptions={{
                        auto: ['compress', 'format'],
                      }}
                      sizes={srcSetSizes}
                    />
                  </Box>
                )}
                <ButtonsProductCartOptions
                  product={product}
                  collection={collection}
                  variant={variant}
                  detailsPath={getProductPath({ handle })}
                  useIcon={true}
                  fill="horizontal"
                  buttonFlex={true}
                />
              </Box>
            </SmartLink>
          </m.div>
        </Stack>
      </Box>
    </LazyMotion>
  );
};

ProductCardHoverDetails.propTypes = {
  product: PropTypes.object,
  collection: PropTypes.object,
  srcSetSizes: PropTypes.string,
};

export default memo(ProductCardHoverDetails);
